@media (min-width: 300px) {
  .debate_type {
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    gap: '20px'
  }
}

@media (min-width: 800px) {
  .debate_type {
    /* grid-template-columns: repeat(2, 1fr); */
    display: grid;
    gap: 20px;
  }
}